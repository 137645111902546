<div class="container">

  <mat-accordion class="accordion">
    <mat-expansion-panel #matExpansion [expanded]="true" class="expansion-panel" >
      <mat-expansion-panel-header>
        <mat-panel-title> Notes {{appt.communications  ? '(' + appt.communications.length +')' : '(0)'}}</mat-panel-title>
        <mat-panel-description><button mat-raised-button
          class="small-button"
          color="primary"

          (click)="addNote(); stopPropagation($event)">Add Note</button></mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container *ngIf="!isMobile()">


        <div class="row">
          <div class="col-12">
            <table class="table table-striped">
              <thead *ngIf="appt.communications && appt.communications.length > 0">
                <tr>
                  <th class="left">Date</th>
                  <th class="left">Name</th>
                  <th class="left">Message</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let note of appt.communications">
                  <td class="align-top date">{{ note.date | date : "short"}}</td>
                  <td class="align-top pr-3">{{ note.name }}</td>
                  <td class="align-top pb-3"
                      [innerHTML]="note.message"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isMobile()">
        <!-- In mobile, each row should have Date and Name on the first line, and the message bellow -->
        <div class="row">
          <div class="col-12">
            <div *ngFor="let note of appt.communications"
                 class="mb-2">
              <div class="row">
                <div class="col-12 bold">
                  <span class="date mr-3">{{ note.date | date : "short"}}</span>
                  <span class="pr-3">{{ note.name }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-12"
                     [innerHTML]="note.message"></div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</div>
