import { AmountTx } from '../../bank/AmountTx';
import { AppointmentModel } from '../../appointmentModel';
import { CareGiverModel, CareGiverTypeEnum } from '../../careGiverModel';
import { CsBankModel } from '../../bank/Bank';
import { TaxeModel } from '../../taxeModel';
import { GeneralUtil } from '../general.util';
import { AppointmentExtensionModel } from '../../appointment-extension.model';
import { TaxesUtil } from './taxes.util';
import { CareSeekerModel } from '../../careSeekerModel';
import { LoRatePeriod, LoRates } from '../../CgRates';
import { DateUtil } from '../date/date.util';
import { CareTeamMember } from '../../CareTeamModel';

export const FIXED_RATE = 6;
export const LPN_RATE = 40.0;
export const PAB_RATE = 30.0;
export const RN_RATE = 45.0;
export const PSW_RATE = 25.0;
export const APRN_RATE = 100.0;

export class PricingUtil {
  static CareTeam = class {
    static filterCareTeam(
      loId: string,
      cts: CareTeamMember[],
    ): CareTeamMember[] {
      const filtered: CareTeamMember[] = [];
      for (const ct of cts) {
        // if the ct has patients
        if (ct.patients && ct.patients.length > 0) {
          // if the patients list includes the loId
          const patient = ct.patients.find((p) => p.id === loId);
          if (patient) {
            filtered.push(ct);
          }
        } else {
          filtered.push(ct);
        }
      }
      return filtered;
    }

    static GetMaxRates(
      actives: CareTeamMember[],
      csId: string,
      patientId: string,
      getCost: boolean,
    ): {
      maxRate: number;
      maxCost?: number;
      maxCg: CareTeamMember;
      maxCgName: string;
    } {
      const rates: {
        maxRate: number;
        maxCost?: number;
        maxCg: CareTeamMember;
        maxCgName: string;
      } = {
        maxRate: 0,
        maxCost: 0,
        maxCg: null,
        maxCgName: '',
      };
      for (const active of actives) {
        if (
          active.careGiver.loRates &&
          active.careGiver.loRates.find((r) => r.csId === csId)
        ) {
          const newLoRate = active.careGiver.loRates.find(
            (r) => r.csId === csId && r.loId === patientId,
          );
          if (newLoRate) {
            active.careGiver.calculatedRate = newLoRate.calculatedRate;
            active.careGiver.AreasServedRateAndConditions.CGHourlyRate =
              newLoRate.rate;
          }
        }
      }

      // find entry in actives with the highest calculatedRate
      const highestRateCg = actives.reduce((prev, current) =>
        prev.careGiver.calculatedRate > current.careGiver.calculatedRate
          ? prev
          : current,
      );

      rates.maxRate = highestRateCg.careGiver.calculatedRate;
      if (getCost) {
        rates.maxCost =
          highestRateCg.careGiver.AreasServedRateAndConditions.CGHourlyRate;
      }
      rates.maxCg = highestRateCg;
      rates.maxCgName = GeneralUtil.stripLastNameString(rates.maxCg.name);
      return rates;
    }
  };

  static Rates = class {
    /**
     * Calculates the hourly rate based on the fixed rate and the given rate.
     * @param rate The rate to calculate the hourly rate from.
     * @returns The calculated hourly rate.
     */
    static calculateRate(rate: number, c4gRate?: number): number {
      const c4gUseRate = c4gRate !== null && c4gRate !== undefined ? c4gRate : FIXED_RATE;
      return PricingUtil.roundToTwoDecimals(rate + c4gUseRate);
    }

    static hasLoRateByIds(
      careGiver: CareGiverModel,
      csId: string,
      loId: string,
    ) {
      if (
        careGiver.loRates &&
        careGiver.loRates.length > 0 &&
        careGiver.loRates.find(
          (loRate) => loRate.csId === csId && loRate.loId === loId,
        )
      ) {
        return true;
      } else {
        return false;
      }
    }

    static hasLoRate(
      careGiver: CareGiverModel,
      careSeeker: CareSeekerModel,
      booking: AppointmentModel,
    ): boolean {
      return this.hasLoRateByIds(
        careGiver,
        careSeeker._id,
        booking.lovedOnesPassports[0].Id,
      );
    }

    static getLoRateByIdsFull(
      careGiver: CareGiverModel,
      csId: string,
      loId: string,
    ): LoRates {
      const loRate = careGiver.loRates.find(
        (loRate) => loRate.csId === csId && loRate.loId === loId,
      );
      return loRate;
    }

    static getLoRateByIds(
      careGiver: CareGiverModel,
      csId: string,
      loId: string,
    ): { rate: number; calculatedRate: number } {
      const loRate = careGiver.loRates.find(
        (loRate) => loRate.csId === csId && loRate.loId === loId,
      );
      return { rate: loRate.rate, calculatedRate: loRate.calculatedRate };
    }

    static getLoRate(
      careGiver: CareGiverModel,
      careSeeker: CareSeekerModel,
      booking: AppointmentModel,
    ): { rate: number; calculatedRate: number } {
      return this.getLoRateByIds(
        careGiver,
        careSeeker._id,
        booking.lovedOnesPassports[0].Id,
      );
    }

    static recalculateLoRates(careGiver: CareGiverModel) {
      if (careGiver.loRates && careGiver.loRates.length > 0) {
        careGiver.loRates = careGiver.loRates.map((loRate) => {
          loRate.calculatedRate = this.calculateRate(
            loRate.rate,
            loRate.c4gMargin,
          );
          return loRate;
        });
      }
    }

    static getRate(
      careGiver: CareGiverModel,
      careSeeker: CareSeekerModel,
      booking: AppointmentModel,
    ): { rate: number; calculatedRate: number } {
      const calculatedRate = this.calculateRate(
        careGiver.AreasServedRateAndConditions.CGHourlyRate,
      );
      this.recalculateLoRates(careGiver);

      if (this.hasLoRate(careGiver, careSeeker, booking)) {
        return this.getLoRate(careGiver, careSeeker, booking);
      } else {
        return {
          rate: careGiver.AreasServedRateAndConditions.CGHourlyRate,
          calculatedRate: calculatedRate,
        };
      }
    }

    // static calculateResidenceRate(careGiver: CareGiverModel): number {
    //   switch (careGiver.caregiverType) {
    //     case CareGiverTypeEnum.PSW:
    //       return careGiver.pswResidenceRate;
    //     case CareGiverTypeEnum.PAB:
    //       return careGiver.pabResidenceRate;
    //     case CareGiverTypeEnum.APRN:
    //       return careGiver.aprnResidenceRate;
    //     case CareGiverTypeEnum.RN:
    //       return careGiver.rnResidenceRate;
    //     case CareGiverTypeEnum.LPN:
    //       return careGiver.lpnResidenceRate;
    //     case CareGiverTypeEnum.OTHER:
    //     default:
    //       return careGiver.calculatedRate;
    //   }
    // }

    // static getResidenceRate(careGiver: CareGiverModel): number {
    //   const rate = PricingUtil.calculateResidenceRate(careGiver);
    //   if (rate < careGiver.calculatedRate) {
    //     return 0;
    //   } else {
    //     return rate;
    //   }
    // }
  };

  static Cost = class {
    static getStandardCheckOutCost(
      hours: number,
      bookingAppointment: AppointmentModel,
      careGiver: CareGiverModel,
      careSeeker: CareSeekerModel,
    ): {
      cost: AmountTx;
      charge: AmountTx;
      c4g: AmountTx;
      subsidizedHours: number;
      standardHours: number;
    } {
      const amountToPay: number = PricingUtil.roundToTwoDecimals(
        hours * bookingAppointment.hourlyCost,
      );
      const taxesToPay: TaxeModel[] = TaxesUtil.calculateTaxes(
        amountToPay,
        TaxesUtil.getCgTaxeRates(careGiver),
      );
      const amountToCollect: number = PricingUtil.roundToTwoDecimals(
        hours * bookingAppointment.hourlyCharge,
      ); // By minutes
      const taxesToCollect: TaxeModel[] = TaxesUtil.calculateTaxes(
        amountToCollect,
        TaxesUtil.getCsTaxeRates(careSeeker),
      );
      return {
        charge: { amount: amountToCollect, taxes: taxesToCollect },
        cost: { amount: amountToPay, taxes: taxesToPay },
        c4g: {
          amount: PricingUtil.roundToTwoDecimals(amountToCollect - amountToPay),
          taxes: taxesToCollect,
        },
        subsidizedHours: 0,
        standardHours: hours,
      };
    }

    static getPeriodStartDate(cgLoRates: LoRates, date: Date): Date {
      // The return date must be on the same day of the week as the cgLoRates start date
      // The return date must be before the date
      return DateUtil.getPeriodStartDate(
        new Date(cgLoRates.subsidizedStartDate),
        date,
        cgLoRates.subsidizedResetPeriod,
      );
    }

    static getPeriodEndDate(cgLoRates: LoRates, date: Date): Date {
      // add subsidizedResetPeriod (days) to the date
      const to = DateUtil.addDays(date, cgLoRates.subsidizedResetPeriod);
      DateUtil.beginingtOfDay(to);
      return to;
    }

    static getOrCreatePeriod(
      cgLoRates: LoRates,
      date: Date,
      periods: LoRatePeriod[],
    ) {
      let period: LoRatePeriod = periods.find(
        (period) => period.from <= date && period.to > date,
      );

      if (!period) {
        // Create a new period using the start date of the cgLoRates and the subsidizedResetPeriod
        const from = this.getPeriodStartDate(cgLoRates, date);
        period = {
          // Start date of the cgLoRates + subsidizedResetPeriod until it fit the date
          from: from,
          to: this.getPeriodEndDate(cgLoRates, from),
          hoursLeft: cgLoRates.subsidizedHoursPerPeriod,
          transactions: [],
        };
        periods.push(period);
      }

      return period;
    }

    static getSubsidizedLoRateCheckOutCost(
      date: Date,
      hours: number,
      bookingAppointment: AppointmentModel,
      careGiver: CareGiverModel,
      careSeeker: CareSeekerModel,
      periods: LoRatePeriod[],
    ): {
      cost: AmountTx;
      charge: AmountTx;
      c4g: AmountTx;
      subsidizedHours: number;
      standardHours: number;
    } {
      const apptLoRates = bookingAppointment.loRates;

      const period = this.getOrCreatePeriod(
        apptLoRates,
        new Date(date),
        periods,
      );
      // The amounts are calculated based on how many hours are left in the period, the amount of hours
      // that fit in the period will use the subsidized rate, the rest will use the standard rate
      const subsidizedHours = Math.min(hours, period.hoursLeft);
      const standardHours = hours - subsidizedHours;

      const amountToPay: number = PricingUtil.roundToTwoDecimals(
        subsidizedHours * bookingAppointment.loRates.subsidizedRate +
          standardHours * bookingAppointment.loRates.rate,
      );
      const taxesToPay: TaxeModel[] = TaxesUtil.calculateTaxes(
        amountToPay,
        TaxesUtil.getCgTaxeRates(careGiver),
      );
      const amountToCollect: number = PricingUtil.roundToTwoDecimals(
        subsidizedHours *
          (bookingAppointment.loRates.subsidizedRate +
            bookingAppointment.loRates.subsidizedC4gMargin) +
          standardHours *
            (bookingAppointment.loRates.rate +
              bookingAppointment.loRates.c4gMargin),
      ); // By minutes
      const taxesToCollect: TaxeModel[] = TaxesUtil.calculateTaxes(
        amountToCollect,
        TaxesUtil.getCsTaxeRates(careSeeker),
      );
      return {
        charge: { amount: amountToCollect, taxes: taxesToCollect },
        cost: { amount: amountToPay, taxes: taxesToPay },
        c4g: {
          amount: PricingUtil.roundToTwoDecimals(amountToCollect - amountToPay),
          taxes: taxesToCollect,
        },
        subsidizedHours: subsidizedHours,
        standardHours: standardHours,
      };
    }

    static getLoRateCheckOutCost(
      date: Date,
      hours: number,
      bookingAppointment: AppointmentModel,
      careGiver: CareGiverModel,
      careSeeker: CareSeekerModel,
      periods: LoRatePeriod[],
    ): {
      cost: AmountTx;
      charge: AmountTx;
      c4g: AmountTx;
      subsidizedHours: number;
      standardHours: number;
    } {
      const apptLoRates = bookingAppointment.loRates;

      // The amounts are calculated using the apptLoRates because the CG can change the rates at any time

      if (apptLoRates.subsidized) {
        return this.getSubsidizedLoRateCheckOutCost(
          date,
          hours,
          bookingAppointment,
          careGiver,
          careSeeker,
          periods,
        );
      } else {
        const amountToPay: number = PricingUtil.roundToTwoDecimals(
          hours * apptLoRates.rate,
        );
        const taxesToPay: TaxeModel[] = TaxesUtil.calculateTaxes(
          amountToPay,
          TaxesUtil.getCgTaxeRates(careGiver),
        );
        const amountToCollect: number = PricingUtil.roundToTwoDecimals(
          hours * apptLoRates.calculatedRate,
        ); // By minutes
        const taxesToCollect: TaxeModel[] = TaxesUtil.calculateTaxes(
          amountToCollect,
          TaxesUtil.getCsTaxeRates(careSeeker),
        );
        return {
          charge: { amount: amountToCollect, taxes: taxesToCollect },
          cost: { amount: amountToPay, taxes: taxesToPay },
          c4g: {
            amount: PricingUtil.roundToTwoDecimals(
              amountToCollect - amountToPay,
            ),
            taxes: taxesToCollect,
          },
          subsidizedHours: 0,
          standardHours: hours,
        };
      }
    }

    static getCheckOutCost(
      date: Date,
      careGiver: CareGiverModel,
      careSeeker: CareSeekerModel,
      bookingAppointment: AppointmentModel,
      hours: number,
      periods: LoRatePeriod[],
    ): {
      cost: AmountTx;
      charge: AmountTx;
      c4g: AmountTx;
      subsidizedHours: number;
      standardHours: number;
    } {
      // check the Appointment to see if it has a loRates
      if (bookingAppointment.loRates) {
        return this.getLoRateCheckOutCost(
          date,
          hours,
          bookingAppointment,
          careGiver,
          careSeeker,
          periods,
        );
      } else {
        return this.getStandardCheckOutCost(
          hours,
          bookingAppointment,
          careGiver,
          careSeeker,
        );
      }
    }

    static calculateExtensionCost(
      extension: AppointmentExtensionModel,
      cg: CareGiverModel,
      cs: CareSeekerModel,
      appt: AppointmentModel,
    ) {
      const cgTaxes = TaxesUtil.getCgTaxeRates(cg);
      const csTaxes = TaxesUtil.getCsTaxeRates(cs);

      // Get the rate and check for LoRate
      const rate = PricingUtil.Rates.getRate(cg, cs, appt);
      extension.charge = TaxesUtil.applyTaxes(
        extension.hours * rate.calculatedRate,
        csTaxes,
      );
      extension.cost = TaxesUtil.applyTaxes(
        extension.hours * rate.rate,
        cgTaxes,
      );
    }

    static calculateExtensionCostByMaxRate(
      extension: AppointmentExtensionModel,
      cs: CareSeekerModel,
      maxRates: { cost: number; charge: number },
    ) {
      const cgTaxes = [];
      const csTaxes = TaxesUtil.getCsTaxeRates(cs);
      extension.charge = TaxesUtil.applyTaxes(
        extension.hours * maxRates.charge,
        csTaxes,
      );
      extension.cost = TaxesUtil.applyTaxes(
        extension.hours * maxRates.cost,
        cgTaxes,
      );
    }
  };

  static total(amountTx: AmountTx): number {
    const taxes = amountTx.taxes
      .map((t) => t.tax)
      .reduce((total, tax) => total + tax, 0);
    return amountTx.amount + taxes;
  }

  static toCurrency(amount: number): string {
    return Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD',
    }).format(amount);
  }

  static totalTx(taxes: TaxeModel[]) {
    return taxes.map((t) => t.tax).reduce((total, tax) => total + tax, 0);
  }

  static percentAmountTx(amount1: AmountTx, percent: number): AmountTx {
    const result: AmountTx = {
      amount: PricingUtil.roundToTwoDecimals((amount1.amount * percent) / 100),
      taxes: PricingUtil.percentTx(amount1.taxes, percent),
    };
    return result;
  }

  static addAmountTx(amount1: AmountTx, amount2: AmountTx): AmountTx {
    const result: AmountTx = {
      amount: amount1.amount + amount2.amount,
      taxes: PricingUtil.addTx(amount1.taxes, amount2.taxes),
    };
    return result;
  }

  static subtractAmountTx(amount1: AmountTx, amount2: AmountTx): AmountTx {
    const result: AmountTx = {
      amount: amount1.amount - amount2.amount,
      taxes: PricingUtil.subtractTx(amount1.taxes, amount2.taxes),
    };
    return result;
  }

  static percentTx(taxes1: TaxeModel[], percent: number): TaxeModel[] {
    const result: TaxeModel[] = [];

    for (const element of taxes1) {
      result.push({
        code: element.code,
        name: element.name,
        tax: PricingUtil.roundToTwoDecimals((element.tax * percent) / 100),
        type: element.type,
      });
    }
    return result;
  }

  static addTx(taxes1: TaxeModel[], taxes2: TaxeModel[]): TaxeModel[] {
    const array: TaxeModel[] = [...taxes1, ...taxes2];
    const result: TaxeModel[] = [];

    for (const element of array) {
      const existing = result.find((r) => r.code === element.code);
      if (existing) {
        existing.tax += element.tax;
      } else {
        result.push({ ...element });
      }
    }

    return result;
  }

  static subtractTx(taxes1: TaxeModel[], taxes2: TaxeModel[]): TaxeModel[] {
    const result: TaxeModel[] = [];

    for (const tx1 of taxes1) {
      const tx2 = taxes2.find((r) => r.code === tx1.code);
      if (tx2) {
        const txResult: TaxeModel = {
          code: tx1.code,
          name: tx1.name,
          tax: tx1.tax - tx2.tax,
          type: tx1.type,
        };
        result.push(txResult);
      } else {
        result.push({ ...tx1 });
      }
    }

    return result;
  }

  static negate(amountToNegate: AmountTx) {
    const retAmount: AmountTx = {
      amount: amountToNegate.amount * -1,
      taxes: PricingUtil.negateTx(amountToNegate.taxes),
    };

    return retAmount;
  }

  /**
   * It takes an array of TaxeModel objects, creates a new array of TaxeModel objects, and then multiplies the tax property
   * of each object by -1
   * @param {TaxeModel[]} taxes - TaxeModel[] - the array of taxes to negate
   * @returns The negTaxes array is being returned.
   */
  static negateTx(taxes: TaxeModel[]): TaxeModel[] {
    const negTaxes: TaxeModel[] = GeneralUtil.deepClone(taxes);
    for (const tax of negTaxes) {
      tax.tax = tax.tax * -1;
    }
    return negTaxes;
  }

  static getCashBalance(cashBalance: CsBankModel) {
    if (!cashBalance) {
      return 0;
    }

    return cashBalance.balance + this.getMargin(cashBalance);
  }

  static getMargin(cashBalance: CsBankModel) {
    return cashBalance.creditMargin && cashBalance.creditMargin > 0
      ? cashBalance.creditMargin
      : 0;
  }

  static roundToTwoDecimals(num: number): number {
    let number = Math.round(num * 100) / 100;
    if (number <= 0.01 && number >= 0) {
      number = 0;
    }
    return number;
  }

  static roundToFifteenMinutesIncrements(timeSpentInHours: number): number {
    // Round to .25 increments
    const timeSpentInMinutes = timeSpentInHours * 60;
    const remainder = timeSpentInMinutes % 15;
    const roundedMinutes =
      remainder < 7.5
        ? timeSpentInMinutes - remainder
        : timeSpentInMinutes + (15 - remainder);
    return roundedMinutes / 60;
  }

  static roundToNoDecimals(num: number): number {
    return Math.round(num);
  }

  static getCharges(appt: AppointmentModel) {
    return appt.hours * appt.hourlyCharge;
  }

  static getCost(appt: AppointmentModel) {
    return appt.hours * appt.hourlyCost;
  }
}
