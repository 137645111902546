import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { DomSanitizer } from '@angular/platform-browser';
import {
  AppointmentModel,
  AppointmentStatusModel,
  CareSeekerModel,
  CareTeamModel,
  DateUtil,
  GeneralUtil,
  NameModel,
} from '@nx-c4g/c4g-models';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'nx-c4g-appointment-summary-template',
  templateUrl: './appointment-summary-template.component.html',
  styleUrls: ['./appointment-summary-template.component.scss'],
})
export class AppointmentSummaryTemplateComponent {
  @Input() text!: string;
  @Input() appt!: AppointmentModel;
  @Input() careSeeker!: CareSeekerModel;
  @Input() ct!: CareTeamModel;
  @Input() isMine = false;
  @Input() mode = '';
  @Output() addedExpansionPanel = new EventEmitter<MatExpansionPanel>();
  @Output() noteAdded = new EventEmitter<{note: string, apptId: string}>();
  @Output() onRemoveCancelledAppointment: EventEmitter<any> = new EventEmitter<any>();

  panels: MatExpansionPanel[] = [];

  constructor(private sanitized: DomSanitizer) {}

  shouldBeIn(): boolean {
    // if the appt startDate is past the current time, and there are no checkin return true
    if (DateUtil.isBefore(this.appt.startDate, new Date())) {
      // && !this.appt.checkInOut || !this.appt.checkInOut.checkIn;
      if (this.appt.status === AppointmentStatusModel.careBookEntryBooked) {
        if (!this.appt.checkInOut || !this.appt.checkInOut.checkIn) return true;
      }
    }
    return false;
  }

  shouldBeOut(): boolean {
    // if the appt startDate is past the current time, and there are no checkin return true
    if (DateUtil.isBefore(this.appt.endDate, new Date())) {
      // && !this.appt.checkInOut || !this.appt.checkInOut.checkIn;
      if (this.appt.status === AppointmentStatusModel.careBookEntryBooked) {
        if (!this.appt.checkInOut || !this.appt.checkInOut.checkOut) return true;
      }
    }
    return false;
  }

  addedExpansionPanelHandler($event: MatExpansionPanel) {
    this.panels.push($event);
    this.addedExpansionPanel.emit($event);
  }

  stopPropagation($event: MouseEvent) {
    $event.stopPropagation();
  }


  sameDay(arg0: Date, arg1: Date): boolean {
    return DateUtil.sameDate(arg0, arg1);
  }

  openNotesDialog(arg0: AppointmentModel) {
    // Pop an alert with the notes
  }

  resolveCt(member: any) {
    if (member && this.ct) {
      const found = this.ct.team.find((tm) => tm.id === member);
      return found!.name;
    } else {
      return '';
    }
  }

  isHis(appt: AppointmentModel) {
    if (this.careSeeker) {
      if (appt.careseeker === this.careSeeker._id) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  allRejected(): any {
    // if appt.visibility === 4 and all the punctualTeam ids are in punctualTeamRefusal, return true
    if (this.appt.visibility === '4') {
      if (this.appt.punctualTeamCGs && this.appt.punctualTeamRefusal) {
        return this.appt.punctualTeamCGs.every((cg) =>
          this.appt.punctualTeamRefusal.includes(cg.id),
        );
      }
    }
    return false;
  }

  getCGNames(appt: AppointmentModel) {
    // Generate HTML string with all the punctual team CGs, if a CG id is in punctualTeamRefusal, use strike-through
    let str = '';
    // start with span with class cg-name
    str += '<div>';
    if (appt.punctualTeamCGs && appt.punctualTeamCGs.length > 0) {
      appt.punctualTeamCGs.forEach((cg, idx) => {
        if (
          appt.punctualTeamRefusal &&
          appt.punctualTeamRefusal.length > 0 &&
          appt.punctualTeamRefusal.includes(cg.id)
        ) {
          str += `<strike>${appt.punctualTeamCGs[idx].name}</strike>`;
          // Add Stop Sign  Mat Icon
          str +=
            '<span class="material-icons" id="smallIcon">do_not_disturb_on</span>';
          str += ', ';
        } else {
          str += `${appt.punctualTeamCGs[idx].name}, `;
        }
      });
    }
    // remove the last comma
    str = str.slice(0, -2);
    // close the span
    str += '</div>';
    return this.sanitized.bypassSecurityTrustHtml(str);

    // if (appt.punctualTeamCGs && appt.punctualTeamCGs.length > 0) {
    //   return GeneralUtil.StringUtils.arrayToString(appt.punctualTeamCGs);
    // } else {
    //   return '';
    // }
  }

  getStrippedName(name: NameModel) {
    return GeneralUtil.stripLastName(name);
  }

  getStrippedNameStr(name: string) {
    return GeneralUtil.stripLastNameString(name);
  }
}
