import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentModel, CommunicationModel } from '@nx-c4g/c4g-models';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatDialog } from '@angular/material/dialog';
import { CheckInNotesComponent } from './check-in-notes.component';
import { Overlay } from '@angular/cdk/overlay';

@Component({
  selector: 'c4g-communication-notes',
  templateUrl: './CommunicationNotes.component.html',
  styleUrl: './CommunicationNotes.component.scss',

})
export class CommunicationNotesComponent implements OnChanges, AfterViewInit {
  @Input() appt: AppointmentModel;
  @Input() disabled = false;
  @Output() noteAdded = new EventEmitter<{note: string, apptId: string}>();
  @Output() addedExpansionPanel = new EventEmitter<MatExpansionPanel>();
    @ViewChildren('matExpansion', { read: MatExpansionPanel })
  panels: QueryList<MatExpansionPanel>;

  /**
   *
   */
  constructor(public dialog: MatDialog, private overlay: Overlay) {}

  ngAfterViewInit(): void {
    this.panels.forEach((panel) => {
      this.addedExpansionPanel.emit(panel);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['appt']) {
      if (this.appt.communications && this.appt.communications.length > 0) {
        this.appt.communications.sort((a, b) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        });
        console.log('Communications:', this.appt.communications);
      }
    }
  }



  stopPropagation($event: MouseEvent) {
    $event.stopPropagation();
  }

  addNote() {
    const dialogRef = this.dialog.open(CheckInNotesComponent, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: this.appt,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        console.log('addNote:', result, this.appt);
        if (!this.appt.communications) {
          this.appt.communications = [];
        }
        const newNote: CommunicationModel = {
          date: new Date(),
          message: result,
          name: 'Administrator',
        };
        // Insert at the beginning of the array
        this.appt.communications.unshift(newNote);
        this.noteAdded.emit({note: result, apptId: this.appt._id});
      }
    });
  }

  isMobile(): boolean {
    return window.innerWidth < 768;
  }
}
