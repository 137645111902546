import { AppointmentModel } from '../../appointmentModel';
import { AppointmentStatusModel, AppointmentTypesModel } from '../../enums/AppointmentTypesModel';
import { DateUtil } from "../date/date.util";

export class BookingUtil {
  static yesterday(): Date {
    const endDate = new Date();
    endDate.setDate(endDate.getDate() - 1);
    return endDate;
  }

  static isPast(date: Date) {
    return (
      (new Date(date).getTime() - Date.now()) / 36e5 < 0
    );
  }

  static cantDeleteReason(appointment: AppointmentModel): string {
    const isPast = DateUtil.isPast(new Date(appointment.startDate));
    const yesterday = BookingUtil.yesterday();

    if (appointment.checkInOut && appointment.checkInOut.checkIn) return "deleteReason.worked";

    if (isPast && appointment.endDate > yesterday) return "deleteReason.24h";

    return "";
  }

  static canDelete(appointment: AppointmentModel): boolean {
    // We can delete
    // * REVERSE BOOKINGS
    //  - future appointments
    //  - past without check-ins (after 1 day)
    // * DIRECT BOOKINGS
    // - future bookings not booked
    // - future bookings booked
    // - past without check-ins (after 1 day)

    // AppointmentStatusModel.booking can always be cancelled since they have not been accepted yet.
    if (appointment.checkInOut && appointment.checkInOut.checkIn) return false;

    if (
      appointment.status === AppointmentStatusModel.careBookEntryBooked ||
      appointment.type === AppointmentTypesModel.blocked
    ) return true;

    const isPast = DateUtil.isPast(new Date(appointment.startDate));
    const yesterday = BookingUtil.yesterday();

    // future without check-ins
    if (!isPast && !(appointment.checkInOut && appointment.checkInOut.checkIn)) return true;
    //  - past without check-ins (after 1 day)
    return isPast && !(appointment.checkInOut && appointment.checkInOut.checkIn) && appointment.endDate <= yesterday;
  }


  static getStart(startDate: Date, startTime: number, startHalf: number, startAMPM: string): Date | null {
    if (
      !startDate ||
      !startTime ||
      (startHalf === undefined) ||
      (startHalf === null) ||
      !startAMPM ||
      !(startDate instanceof Date) ||
      startTime < 1 ||
      startTime > 12 ||
      !([0, 30].includes(startHalf)) ||
      !(["AM", "PM"].includes(startAMPM))
    ) {
      return null;
    }

    let hrs = startTime;
    if (startAMPM == "PM" && hrs < 12) hrs = hrs + 12;
    if (startAMPM == "AM" && hrs == 12) hrs = hrs - 12;

    startDate.setHours(hrs);
    startDate.setMinutes(startHalf);
    startDate.setSeconds(0, 0);


    return startDate;
  }

  static apptsAroundDate(apptArray: any[], start: Date) {
    return apptArray.filter((appt) => DateUtil.aroundDay(appt.startDate, start));
  }


  static apptsOverlaps(apptArray: any[], start: Date, end: Date): any[] {
    const overlapArray: any[] = [];
    const newArray: any[] = [];

    for (let i = 0; i < apptArray.length; i++) {
      newArray.push(apptArray[i]);
    }

    for (let i = 0; i < newArray.length; i++) {
      const appt: AppointmentModel = newArray[i];
      const range1 = { start: appt.startDate, end: appt.endDate };
      const range2 = { start: start, end: end };
      if (DateUtil.overlaps(range1, range2)) {
        overlapArray.push(appt);
      }
    }
    return overlapArray;
  }


  static expandAppointments(
    appt: AppointmentModel,
    dates: Date[]
  ): AppointmentModel[] {
    const appointements: AppointmentModel[] = [];

    const hour = appt.startDate.getHours();
    const min = appt.startDate.getMinutes();
    const endHour = appt.endDate.getHours();
    const endMin = appt.endDate.getMinutes();

    // To calculate the no. of days between two dates
    const dayStartDate = new Date(appt.startDate);
    dayStartDate.setHours(0, 0, 0, 0);
    const dayEndDate = new Date(appt.endDate);
    dayEndDate.setHours(0, 0, 0, 0);

    const differenceInTime = dayEndDate.getTime() - dayStartDate.getTime();
    const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

    for (let i = 0; i < dates.length; i++) {
      const newAppt = new AppointmentModel();
      newAppt.allDay = appt.allDay;
      newAppt.description = appt.description;
      newAppt.text = appt.text;
      newAppt.status = appt.status;
      newAppt.startDate = dates[i];
      newAppt.startDate.setHours(hour, min, 0, 0);
      newAppt.endDate = new Date(dates[i]);
      newAppt.endDate.setDate(dates[i].getDate() + differenceInDays);
      newAppt.endDate.setHours(endHour, endMin, 0, 0);
      appointements.push(newAppt);
    }

    return appointements;
  }

  static horaireToAppointements(horaire: any[]): AppointmentModel[] {
    const appointements: AppointmentModel[] = [];
    for (let i = 0; i < horaire.length; i++) {
      const appt: AppointmentModel = new AppointmentModel();
      appt.type = horaire[i].type;
      appt.ignoreOT = horaire[i].ignoreOT;

      appt.careBookId = horaire[i].careBookId;
      appt.paidSubsidizedHours = horaire[i].paidSubsidizedHours;
      appt.hourlyCost = horaire[i].hourlyCost;
      appt.hourlyCharge = horaire[i].hourlyCharge;
      appt.loRates = horaire[i].loRates;
      appt.reserve = horaire[i].reserve;
      appt.cost = horaire[i].cost;
      appt.charge = horaire[i].charge;
      appt.refund = horaire[i].refund;
      appt.penaltyCost = horaire[i].penaltyCost;
      appt.penaltyCharge = horaire[i].penaltyCharge;
      appt.penaltyC4g = horaire[i].penaltyC4g;
      appt.c4g = horaire[i].c4g;
      appt.paidToCg = horaire[i].paidToCg;
      appt.maxRate = horaire[i].maxRate;
      appt.punctualTeam = horaire[i].punctualTeam;
      appt.visibility = horaire[i].visibility;
      appt.extensionRequest = horaire[i].extensionRequest;
      appt.remainingSubsidizedHours = horaire[i].remainingSubsidizedHours;

      appt.text = horaire[i].text;
      appt.endDate = new Date(horaire[i].endDate);
      appt.startDate = new Date(horaire[i].startDate);
      appt.description = horaire[i].description;
      appt.status = horaire[i].status;
      appt.checkInOut = horaire[i].checkInOut;
      // appt.tasks = horaire[i].tasks;
      appt.extensionRequest = horaire[i].extensionRequest;

      appt.hours = horaire[i].hours ? horaire[i].hours : 0;
      appt.address = horaire[i].address ? horaire[i].address : "";
      appt.careseeker = horaire[i].careseeker ? horaire[i].careseeker : "";
      appt.caregiver = horaire[i].caregiver ? horaire[i].caregiver : "";
      appt.caregiverName = horaire[i].caregiverName
        ? horaire[i].caregiverName
        : "";
      appt.careseekerName = horaire[i].careseekerName
        ? horaire[i].careseekerName
        : "";
      appt.lovedOnesPassports = horaire[i].lovedOnesPassports
        ? horaire[i].lovedOnesPassports
        : [];
      // appt.lovedOnes = horaire[i].lovedOnes ? horaire[i].lovedOnes : [];
      appt._id = horaire[i]._id ? horaire[i]._id : "";

      appointements.push(appt);
    }
    return appointements;
  }

  static clean(obj: any): any {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }


  static isAtDate(someDate: Date, targetDate: Date): boolean {
    const someDateCopy = new Date(someDate);
    someDateCopy.setHours(0, 0, 0, 0); // Set time to midnight
    const targetDateCopy = new Date(targetDate);
    targetDateCopy.setHours(0, 0, 0, 0); // Set time to midnight
    return someDateCopy.getTime() === targetDateCopy.getTime();
  }

  static isWithinFirst3HoursOfTomorrow(someDate: Date): boolean {
    const currentDate = new Date();
    const tomorrow = DateUtil.addTime(currentDate, 24, 0,0);
    tomorrow.setHours(0, 0, 0, 0); // Set time to midnight

    const someDateCopy = new Date(someDate);

    const threeHoursFromMidnight = new Date(tomorrow);
    threeHoursFromMidnight.setHours(3); // Set time to 3 AM

    return someDateCopy >= tomorrow && someDateCopy <= threeHoursFromMidnight;
  }

  static within48hours(d1) {
    const today = new Date();
    const date1 = new Date(d1);
    return Math.abs(today.getTime() - date1.getTime()) / 36e5 <= 48;
  }
}
